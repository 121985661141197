import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { ComponentsModule } from './components/components.module';
import { FlexLayoutModule } from '@angular/flex-layout';


const moduleList = [
  MaterialModule,
  FlexLayoutModule,
  FormsModule,
  ReactiveFormsModule,
  ComponentsModule
];

const declarationList = [
];

@NgModule({
  declarations: [...declarationList,],
  imports: [
    CommonModule,
    ...moduleList
  ],
  exports: [
    ...moduleList,
    ...declarationList,
  ],
  providers: [
    DatePipe,
  ],
})
export class SharedModule { }
