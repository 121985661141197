import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonLoggerService } from './core/services/logger/common-logger.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'appyhours-website';
  constructor(){
    if (environment.production) {
      CommonLoggerService.enableProductionMode();
    }
  }
}
