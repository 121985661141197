import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModalComponent } from './modal/router-modal/router-modal.component';


@NgModule({
  declarations: [
    AlertDialogComponent,
    RouterModalComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,

  ],
  exports: [
    AlertDialogComponent,
    RouterModalComponent,
    ]
})
export class ComponentsModule { }
